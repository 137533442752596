import React, { createContext, useEffect } from "react";
import { node } from "prop-types";
import { useLocalStorage } from "react-use";
import { path } from "ramda";

const getAccessToken = path(["accessToken"]);
const getExpiration = path(["expiresIn"]);
const getName = path(["details", "name"]);
const getIsUploader = path(["configuration", "isUploader"]);
const getRole = path(["details", "role"]);

const SessionContext = createContext({});

const SessionProvider = ({ children }) => {
  const [session, setSession] = useLocalStorage("session");
  const token = getAccessToken(session);
  const role = getRole(session);
  const expiration = getExpiration(session);
  const businessName = getName(session);
  const isUploader = getIsUploader(session);

  useEffect(() => {
    if (Date.now() > expiration) {
      setSession();
    }
  }, [token, expiration, setSession]);

  return (
    <SessionContext.Provider
      value={{ token, businessName, role, setSession, isUploader }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: node.isRequired,
};

export default SessionProvider;
export { SessionContext };
