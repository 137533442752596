import React, { Suspense } from "react";
import {
  CircularProgress,
  CssBaseline,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import esDateLocale from "date-fns/locale/es";
import theme from "../../commons/theme";
import { Routes } from "..";
import { EmptyScreen, SessionProvider } from "../../components";

const Loader = () => (
  <EmptyScreen>
    <CircularProgress size="5em" />
  </EmptyScreen>
);

const App = () => {
  return (
    <SessionProvider>
      <MaterialThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <LocalizationProvider
            locale={esDateLocale}
            dateAdapter={DateFnsUtils}
          >
            <CssBaseline />
            <Suspense fallback={<Loader />}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Suspense>
          </LocalizationProvider>
        </StyledComponentsThemeProvider>
      </MaterialThemeProvider>
    </SessionProvider>
  );
};

export default App;
