import { lazy } from "react";

export { default as EmptyScreen } from "./EmptyScreen";
export { default as SessionProvider, SessionContext } from "./SessionProvider";
export const AppBreadcrum = lazy(() => import("./AppBreadcrum"));
export const AppHeader = lazy(() => import("./AppHeader"));
export const BasePage = lazy(() => import("./BasePage"));
export const Box = lazy(() => import("./Box"));
export const Checkbox = lazy(() => import("./Checkbox"));
export const InputText = lazy(() => import("./InputText"));
export const InsightCard = lazy(() => import("./InsightCard"));
export const SettingsMenu = lazy(() => import("./SettingsMenu"));
export const TransactionsTable = lazy(() => import("./TransactionsTable"));
