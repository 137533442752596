import { createMuiTheme } from "@material-ui/core/styles";
import { mergeDeepLeft } from "ramda";

export default ((theme) => {
  const palette = {
    blue: theme.palette.augmentColor({ main: "#172D4C" }),
    lightblue: theme.palette.augmentColor({ main: "#29CDED" }),
    gray: theme.palette.augmentColor({ main: "#DBDCDD" }),
    lightgray: theme.palette.augmentColor({ main: "#F7FAFC" }),
    primary: theme.palette.augmentColor({ main: "#172D4C" }),
    secondary: theme.palette.augmentColor({ main: "#29CDED" }),
  };
  const overrides = {
    MuiTableCell: {
      head: {
        backgroundColor: "#F1F3F8",
        textTransform: "uppercase",
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#F5FAFD",
        },
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: null,
        },
      },
    },
    MuiTypography: {
      colorSecondary: {
        color: palette.secondary.dark,
      },
    },
  };
  const shadows = theme.shadows.map((shadow) =>
    shadow
      .replace("rgba(0,0,0,0.2)", "rgba(0,0,0,0.1)")
      .replace("rgba(0,0,0,0.14)", "rgba(0,0,0,0.07)")
      .replace("rgba(0,0,0,0.12)", "rgba(0,0,0,0.06)")
  );

  return mergeDeepLeft(
    {
      palette,
      overrides,
      shadows,
    },
    theme
  );
})(createMuiTheme({}));
